import styled from 'styled-components';

export const StyledCard = styled.div`
  max-width: 100%fit-content;
  padding: 18px 10px;
  font-family: var(--services-font);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 29px 0 rgb(18, 66, 101, 8%);

  &:hover {
    color: var(--p-color);
    border: 3px solid #5e5e5e;
  }
  @media (min-width: 786px) {
    max-width: calc(100% / 5) - 90px;
    padding: 10px 10px;
  }

  @media (min-width: 1168px) {
    max-width: calc(100% / 5);
    padding: 10px 10px;
  }
`;

export const Head = styled.div`
  color: var(--primary-color);
  margin-bottom: 10px;
  svg {
    font-size: 35px;
  }
  img {
    width: 100%;
  }
`;

export const Body = styled.div`
  h3 {
    color: var(--dark-color);
    margin-bottom: 14px;
  }
  p {
    color: #000;
    line-height: 1.5;
    font-size: 15px;
  }
`;
