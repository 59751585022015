import React from 'react';
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
  Footerlanguagetext,
} from './Footer2-styled';
//
import { useNav } from '../../customHooks/useNav';
//
import { AiOutlineFacebook } from 'react-icons/ai';
import { BsInstagram } from 'react-icons/bs';

const Footer2 = () => {
  const contactRef = useNav('Contact');
  return (
    <section ref={contactRef} id="contactContainer">
      <Box id="contact">
        {/* <h1 style={{ color: 'green', textAlign: 'center', marginTop: '-50px' }}>
        GeeksforGeeks: A Computer Science Portal for Geeks
      </h1> */}
        <Container>
          <Row>
            <Column>
              <Heading>Contact Us</Heading>
              <FooterLink disabled>work@intechflex.eu</FooterLink>
              <FooterLink disabled>info@intechflex.eu</FooterLink>
            </Column>
            <Column>
              <Heading></Heading>
              <Footerlanguagetext disabled>EN / LTU / RU </Footerlanguagetext>
              <FooterLink disabled>Tomas</FooterLink>
              <FooterLink disabled>+37062700909 </FooterLink>
            </Column>
            <Column>
              <Heading></Heading>
              <Footerlanguagetext disabled>EN / PL</Footerlanguagetext>
              <FooterLink disabled>Sandra</FooterLink>
              <FooterLink disabled>+48517351622</FooterLink>
            </Column>
            <Column>
              <Heading>Social Media</Heading>
              <FooterLink href="#">
                <AiOutlineFacebook />
                <span style={{ marginLeft: '10px' }}>Facebook</span>
              </FooterLink>
              <FooterLink href="#">
                <BsInstagram />
                <span style={{ marginLeft: '10px' }}>Instagram</span>
              </FooterLink>
            </Column>
          </Row>
        </Container>
      </Box>
    </section>
  );
};
export default Footer2;
