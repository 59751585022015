// import PropTypes from 'prop-types';
// import { StyledAbout, StyledMainAbout, Bodyabout } from './About-styled';
import { Heading } from '../Hero/Hero.styled';
import { useNav } from '../../customHooks/useNav';

const About = () => {
  const aboutRef = useNav('About');

  return (
    <section ref={aboutRef} id="aboutContainer">
      <Heading>
        <h1 className="aboutussector" id="aboutus">
          ABOUT US
        </h1>

        <h3>
          We are fully commited to make that you are satisfied with your work
          and that our clients can achieve their goals. We combine our people
          knowledge and experience with the needs of our clients.
        </h3>
      </Heading>
    </section>
  );
};

// About.propTypes = {
//   icon: PropTypes.node,
//   title: PropTypes.string.isRequired,
//   body: PropTypes.string.isRequired,
// };

export default About;
