import React from 'react';
import { useNav } from '../customHooks/useNav';
import './Page.css';
// while ermail js formik form not ready commented on
// import Button from '../components/Button/Button';
import Card from '../components/Card/Card';
import { Fade, Heading, Services } from '../components/Hero/Hero.styled';
import { GiWindowBars } from 'react-icons/gi';
import { MdGpsNotFixed } from 'react-icons/md';
import { GiElectric } from 'react-icons/gi';
import { GiPipes } from 'react-icons/gi';
import { ImPaintFormat } from 'react-icons/im';

// import Header from '../components/Header/Header';
// import Hero from '../components/Hero/Hero';
// import About from './components/About/About';
// import Contact from './Footer/Footer2';
//

const Home = () => {
  // useNav takes in a navLinkId and returns a ref
  // this ref is used to register the navLinkId that's
  // currently in view, and apply activeClass styling
  // to the corresponding nav childElement

  const homeRef = useNav('Home');

  return (
    <section ref={homeRef} id="homeContainer">
      <Fade />
      <Heading className="container" id="home">
        <h1>INDUSTRIAL SERVICES</h1>
        <h3>
          High-quality proffesional cooperation leads to the best results!
        </h3>
        {/* <Button large text="Get in touch with us" /> */}
      </Heading>
      <Services className="container">
        <Card
          icon={<GiWindowBars />}
          title="Scaffolding"
          body="The European Standard, BS EN 12811-1. In Tech Flex is a safe working platform and access suitable for work crews to carry The European Standard re quirements set. "
        />
        <Card
          icon={<MdGpsNotFixed />}
          title="Pipefitter"
          body="In Tech Flex install, assemble, fabricate, maintain, repair, and troubleshoot pipe carrying fuel, chemicals, water, steam, and air in heating, cooling, lubricating, and various other process piping systems."
        />
        <Card
          icon={<GiPipes />}
          title="Industrial Isolation"
          body="Working with most of Industrial inerface and  new alternatives such as  control interface. We provide required control lines and software control applications."
        />
        <Card
          icon={<GiElectric />}
          title="Electric Engineering"
          body="We dealing with computer engineering, systems engineering, power engineering, telecommunications, radio-frequency engineering, signal processing, instrumentation, photovoltaic cells, electronics, and optics and photonics."
        />
        <Card
          icon={<ImPaintFormat />}
          title="Industrial Painting"
          body="We have the possibilities which painting machines offers. 
          Industries: marine, medical, military, optical, pharmaceutical, semiconductor, and textile applications. Some industrial paints are applied with brushes or rollers."
        />
      </Services>
    </section>
  );
};

export default Home;
