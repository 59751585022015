import { useState } from 'react';
import NavLink from './NavLink';
import { navLinks } from './navLinks';
//import image asset logo
import IntechFlex from '../assets/logo/color_logo_resized.png';

import './Nav.css';
import {
  // while there is no multilanguage solution
  // Dropdown,
  // DropdownContent,
  Nav,
  StyledHeader,
  Toggle,
} from '../components/Header/Header.styled';
import { FaBars } from 'react-icons/fa';
// dropdown meniu icon from library
// import { BsChevronDown } from 'react-icons/bs';
//

const Navas = () => {
  const [open, setOpen] = useState(false);
  // dropdown meniu icon state show not show
  // const [dropDownOpen, setDropDownOpen] = useState(false);

  return (
    <>
      <StyledHeader>
        <Nav open={open} id="navigation">
          <img src={IntechFlex} alt="IntechFlex" className={'logo-image'} />
          <Toggle>
            <FaBars onClick={() => setOpen(!open)} />
          </Toggle>
          <ul>
            <li>
              {navLinks.map(({ navLinkId, scrollToId }, idx) => (
                <NavLink
                  key={idx}
                  navLinkId={navLinkId}
                  scrollToId={scrollToId}
                />
              ))}
            </li>
            {/* // commented until solution multilanguage become done */}
            {/* <li>
              <Dropdown>
                <a href="#languageModal">
                  <span onClick={() => setDropDownOpen((prev) => !prev)}>
                    Language
                    <BsChevronDown />
                  </span>
                </a>
                <DropdownContent
                  className="dropas"
                  open={dropDownOpen}
                  id="languageModal"
                >
                  <ul id="languageModal">
                    <li>
                      <a href="/nl">NL</a>
                    </li>
                    <li>
                      <a href="/pl">PL</a>
                    </li>
                    <li>
                      <a href="/lt">LT</a>
                    </li>
                  </ul>
                </DropdownContent>
              </Dropdown>
            </li> */}
          </ul>
          {/* <span>
          {navLinks.map(({ navLinkId, scrollToId }, idx) => (
            <NavLink key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
          ))}
        </span> */}

          {/* ///////////////// */}
          {/* <Nav open={open}>
        <Logo className="logas">InTechFlex</Logo>
        <Toggle ref={homeRef}>
          <FaBars onClick={() => setOpen(!open)} />
        </Toggle>
        <ul className="uldroplistas">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
          <li>
            <Dropdown>
              <a href="/#">
                <span onClick={() => setDropDownOpen((prev) => !prev)}>
                  Language
                  <BsChevronDown />
                </span>
              </a>
              <DropdownContent className="dropas" open={dropDownOpen}>
                <ul>
                  <li>
                    <a href="/nl">NL</a>
                  </li>
                  <li>
                    <a href="/pl">PL</a>
                  </li>
                  <li>
                    <a href="/lt">LT</a>
                  </li>
                </ul>
              </DropdownContent>
            </Dropdown>
          </li>
        </ul>
      </Nav> */}
        </Nav>
      </StyledHeader>
    </>
  );
};

export default Navas;
