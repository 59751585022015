import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import App from './App';

// const container = document.getElementById('app');
// const root = createRoot(container); // createRoot(container!)
// root.render(
//   <BrowserRouter>
//     <App />
//   </BrowserRouter>
// );

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
