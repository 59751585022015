import './App.css';
import { GlobalStyles } from './GlobalStyles';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import Header from './components/Header/Header';
// import Hero from './components/Hero/Hero';
// import About from './components/About/About';
// import Footer2 from './components/Footer/Footer2';

import { Navas } from './nav';
import { Main } from './pages';
import NavProvider from './context/NavContext';
import './App.js';
// const rootElement = document.getElementById('root');
// render(
//   <BrowserRouter>
//     <GlobalStyles />
//     <Routes>
//       <Route path="/" element={<Hero />} />
//       <Route path="about" element={<Services />} />
//       <Route path="contact" element={<Footer2 />} />

//       {/* <Services className="container">
//         <About
//           title="Scaffolding"
//           body="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi"
//         >
//           {' '}
//         </About>
//         <About
//           title="Scaffolding"
//           body="Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi"
//         >
//           {' '}
//         </About>
//       </Services> */}
//     </Routes>
//   </BrowserRouter>,
//   rootElement
// );
/////////////////
function App() {
  return (
    <>
      <NavProvider>
        <GlobalStyles />
        <Navas />
        <Main />
      </NavProvider>
    </>
  );
}

export default App;
