import React from 'react';
// import { Home, About, Contact } from './';
import Contact from '../components/Footer/Footer2';
// import Hero from '../components/Hero/Hero';

// import Cards from '../components/Cards/Cards';
import Home from './Home';
import About from '../components/About/About';
//
// import Modal from '../components/Modal/Modal';
// import styles from '../App.css';

const Main = () => {
  return (
    <>
      <Home />
      <About />
      <Contact />
    </>
  );
};

export default Main;
