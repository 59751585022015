import styled from 'styled-components';

export const Box = styled.div`
  padding: 40px 10px 5px 5px;
  background: var(--dark-color);
  position: relative;
  bottom: 0;
  width: 100%;
  @media (max-width: 1000px) {
    padding: 20px 50px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  /* background: red; */
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 60px;
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));
  grid-gap: 20px;

  @media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
`;

export const FooterLink = styled.a`
  color: #fff;
  margin-bottom: 20px;
  font-size: 15px;
  font-family: var(--hero-font);
  text-decoration: none;

  &:hover {
    color: var(--p-color);
    transition: color 600ms ease-in;
  }
`;
//
export const Footerlanguagetext = styled.a`
  color: #fff;
  margin-bottom: 20px;
  font-size: 11px;
  font-family: var(--hero-font);
  text-decoration: none;

  &:hover {
    color: var(--p-color);
    transition: color 600ms ease-in;
  }
`;

export const Heading = styled.p`
  font-size: 24px;
  font-family: var(--nav-font);
  color: var(--primary-color);
  margin-bottom: 40px;
  font-weight: bold;
`;
